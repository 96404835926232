<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>供应商管理</span>
        <el-button @click="add" class="btn" type="primary" size="mini"
          :disabled="roleId == 2 || roleId == 3 || roleId == 4">新增供应商</el-button>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="supplier" label="供应商名称"></el-table-column>
          <el-table-column prop="mqttAccount" label="MQTT账号">
            <template slot-scope="scope">
              {{ scope.row.mqttAccount?scope.row.mqttAccount:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="mqttPwd" label="MQTT密码">
            <template slot-scope="scope">
              {{ scope.row.mqttPwd?scope.row.mqttPwd:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="note" label="备注"></el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.createTime? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page> -->
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';

export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      isChangeOpen: 0,
      isDetailOpen: 0,
      isEditOpen: 0,
      sheshi: [],
      bumen: [],
      leixin: [],
      yuangong: [],
      detailRow: {},
      editRow: {},
      roleId: 0
    };
  },
  methods: {
    dayjs,
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    scrap(row) {
      this.$confirm('确认报废?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceStatus', {
          id: row.id,
          statusId: 4
        }).then(res => {
          this.loadListData()
        })
      })
    },
    edit(row) {
      this.$router.push({ name: 'iotSupplierEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('iotSupplierDelete', {}, {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    add() {
      this.$router.push({ name: 'iotSupplierAdd', params: {
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    onSubmit() {
      this.loadListData()
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('iotSupplierQuery').then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    }
  },
  mounted() {
    this.getLastPage()
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>